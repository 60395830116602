/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
import React, { useState } from 'react';
import { useLanguage } from '@/providers/LanguageProvider';
import MonacoEditor, { Monaco } from '@monaco-editor/react';

import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { System } from '@/components/icons';

import { TraceDetails } from '../trace-details';

interface ISpanDetailsDialog {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  spanData: any;
}

const SpanDetailsDialog: React.FC<ISpanDetailsDialog> = ({
  isOpen,
  onOpenChange,
  spanData,
}) => {
  const [editorHeight, setEditorHeight] = useState({
    events: '50px',
    attributes: '50px',
  });
  const { messages } = useLanguage();
  const langData = messages?.Traces?.span_detail_modal;
  const rowHeader = [langData?.name, langData?.attributes, langData?.events];

  const findItem = (rowHeader: string) => {
    let value;

    switch (rowHeader) {
      case langData?.name:
        value = spanData?.span_name;
        break;
      case langData?.attributes:
        if (spanData?.attributes) {
          value = JSON.stringify(spanData?.attributes, null, 2);
        } else {
          value = JSON.stringify({});
        }
        break;
      case langData?.events:
        if (spanData?.events) {
          value = JSON.stringify(spanData?.events, null, 2);
        } else {
          value = JSON.stringify([]);
        }
        break;
      default:
        value = langData?.not_available;
    }
    return value;
  };

  const handleEditorWillMount = (monaco: Monaco) => {
    monaco.editor.defineTheme('customTheme', {
      base: 'vs-dark', // Use dark mode as the base
      inherit: true, // Inherit other properties from vs-dark
      rules: [
        { token: 'string.key.json', foreground: '#DF3079' },
        { token: 'string.value.json', foreground: '#00A67D' },
        { token: 'number', foreground: '#00A67D' },
      ],
      colors: {
        'editor.background': '#07181E',
      },
    });
  };

  const lineHeight = 16;
  const maxLineLimit = 16;
  const paddingTopBotton = 24;
  const maximumBlockLength = 280;

  const handleEditorDidMount = (editor: any, item: any) => {
    const model = editor.getModel();
    if (model) {
      const lineCount = model.getLineCount();

      const calcHeight =
        lineCount < maxLineLimit
          ? lineCount * lineHeight + paddingTopBotton
          : maximumBlockLength;

      if (item === langData?.attributes) {
        setEditorHeight((prev) => ({
          ...prev,
          attributes: `${calcHeight}px`,
        }));
      } else {
        setEditorHeight((prev) => ({
          ...prev,
          events: `${calcHeight}px`,
        }));
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className="max-w-[960px] border-link-active bg-gradient-sky-dark p-[30px]"
        aria-describedby={undefined}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle>
            <div className="flex items-center gap-2.5">
              <span className="text-[24px] font-normal">
                {langData?.span_id}: {spanData?.span_id}
              </span>

              {spanData?.attributes?.status === 'error' && (
                <Badge
                  variant="destructive"
                  className="h-6 bg-notification-error-dark pl-2 pr-3 font-normal leading-4 text-white hover:bg-notification-error-dark"
                >
                  <span className="mr-1">{System.InfoIcon('h-3.5 w-3.5')}</span>
                  {messages?.General?.error}
                </Badge>
              )}
            </div>
          </DialogTitle>
          <div>
            <TraceDetails
              duration={spanData?.duration_ms}
              startTime={spanData?.start_time}
              endTime={spanData?.end_time}
              workflowName={spanData?.attributes?.workflow_name}
            />
          </div>
        </DialogHeader>
        <div className="h-full overflow-hidden rounded-lg border border-line-secondary">
          {rowHeader?.map((item, index) => (
            <div className="flex" key={index}>
              <div
                className={cn(
                  'z-10 w-[200px] min-w-36 shrink-0 border-r border-r-line-secondary bg-[#093242] p-3 text-body-subtle',
                  index !== 0 && 'border-t border-t-line-secondary'
                )}
              >
                {item}
              </div>
              <div
                className={cn(
                  'flex w-full items-center gap-2 bg-gradient-sky-dark text-body-primary',
                  item === langData?.name
                    ? 'p-3'
                    : '-ml-3 border-t border-t-line-secondary bg-[#07181E]'
                )}
              >
                {item === langData?.name ? (
                  findItem(item!)
                ) : (
                  <MonacoEditor
                    height={
                      item === langData?.events
                        ? editorHeight.events
                        : editorHeight.attributes
                    }
                    value={findItem(item!)}
                    language="json"
                    theme="customTheme"
                    beforeMount={handleEditorWillMount}
                    onMount={(editor) => handleEditorDidMount(editor, item)}
                    options={{
                      readOnly: true,
                      minimap: { enabled: false },
                      scrollBeyondLastLine: false,
                      lineHeight: 16,
                      fontSize: 13,
                      padding: {
                        top: 12,
                        bottom: 12,
                      },
                      scrollbar: { vertical: 'auto', horizontal: 'auto' },
                      lineNumbers: 'off',
                      wordWrap: 'on',
                      renderLineHighlight: 'none',
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SpanDetailsDialog;
