/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
export const convertToUTCDate = (
  date: Date | null,
  isEndOfDay: boolean
): string | null => {
  if (!date) return null;

  const localDate = new Date(date);
  const utcNow = new Date();

  const offsetHours = utcNow.getTimezoneOffset() / -60;
  const localHour = utcNow.getHours();

  // Case 1: Local time is ahead of UTC (positive offset, early hours)
  if (offsetHours > 0 && localHour < offsetHours) {
    localDate.setDate(localDate.getDate() - 1); // Use previous date
  }
  // Case 2: Local time is behind UTC (negative offset, late hours)
  else if (offsetHours < 0 && localHour >= 24 + offsetHours) {
    localDate.setDate(localDate.getDate() + 1); // Use next date
  }
  // Case 3: Local time is in sync with UTC day (no adjustment needed)

  const utcDate = new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      isEndOfDay ? 23 : 0,
      isEndOfDay ? 59 : 0,
      isEndOfDay ? 59 : 0,
      isEndOfDay ? 999 : 0
    )
  );

  return utcDate.toISOString();
};
