/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */
'use client';

import { useLanguage } from '@/providers/LanguageProvider';

import { CustomButton } from '@/components/common';

import { DateRange } from './types';
import { areRangesEqual } from './utils';

interface DateRangeActionsProps {
  setIsOpen: (open: boolean) => void;
  range: DateRange;
  rangeCompare?: DateRange;
  openedRangeRef: React.MutableRefObject<DateRange | undefined>;
  openedRangeCompareRef: React.MutableRefObject<DateRange | undefined>;
  onUpdate: (values: { range: DateRange; rangeCompare?: DateRange }) => void;
  resetValues: () => void;
}

export const DateRangeActions: React.FC<DateRangeActionsProps> = ({
  setIsOpen,
  range,
  rangeCompare,
  openedRangeRef,
  openedRangeCompareRef,
  onUpdate,
  resetValues,
}) => {
  const { messages } = useLanguage();
  const langData = messages?.PromptFilters;
  return (
    <div className="mt-4 flex items-center border-t border-t-line-primary pt-5">
      <div className="flex gap-4">
        <CustomButton
          customSize="regular"
          customVariant="primary"
          onClick={() => {
            setIsOpen(false);
            if (
              !areRangesEqual(range, openedRangeRef.current) ||
              !areRangesEqual(rangeCompare, openedRangeCompareRef.current)
            ) {
              onUpdate({ range, rangeCompare });
            }
          }}
        >
          {langData?.date_range.apply}
        </CustomButton>

        <CustomButton
          customSize="regular"
          customVariant="secondary"
          onClick={() => {
            setIsOpen(false);
            resetValues();
          }}
        >
          {messages?.General?.cancel}
        </CustomButton>
      </div>
    </div>
  );
};
